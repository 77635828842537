/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import AboutInstructor from '../../components/pages/Home/AboutInstructor';
import AdvantagesSection from '../../components/pages/Home/AdvantagesSection';
import BannerSection from '../../components/pages/Home/BannerSection';
import BottomBannerSection from '../../components/pages/Home/BottomBannerSection';
import CompaniesStudentSection from '../../components/pages/Home/CompaniesStudentSection';
import CTABannerSection from '../../components/pages/Home/CTABannerSection';
import GetInTouchSection from '../../components/pages/Home/GetInTouchSection';
import MarketingSection from '../../components/pages/Home/MarketingSection';
import NextSessionSection from '../../components/pages/Home/NextSessionSection';
import WhatIsUtrains from '../../components/pages/Home/WhatIsUtrains';
import WhyUsSection from '../../components/pages/Home/WhyUsSection';
import CourseService from "../../services/CourseService";
import HomePageService from "../../services/HomePageService";

function Home() {
  const [bannerData, setBannerData] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [closedSessions, setClosedSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState();
  const [isLoading, setIsLoading] = useState({
    getAllSessions: { value: false },
    getBannerData: { value: false },
  });
  
  
  const getBannerData = async () => {
    setIsLoading((prevState) => ({
      ...prevState,
      getBannerData: { value: true },
    }));

    const response = await HomePageService.getBannerInfo();
    if (response.status === "success") {
      setBannerData(response.data.map((item) => {
        if (item.type === "Image" || item.type === "Html") {
          return {
            type:
              item.type === "Image"
                ? "image"
                : item.type === "Html"
                ? "html"
                : "",
            url: item.content,
            content: item.content,
            link: item.link,
            alt: item.content,
          };
        }
        return null;
      }).filter(item => item !== null));
    } else if (response.status === "error.server") {
      // TODO:
      setBannerData([]);
    }
    setIsLoading((prevState) => ({
      ...prevState,
      getBannerData: { value: false },
    }));
  };

  const getAllSessions = async () => {
    setIsLoading((prevState) => ({
      ...prevState,
      getAllSessions: { value: true },
    }));

    const response = await CourseService.getAllSessions();

    if (response.status === "success") {
      setSessionsList(response.data.result);
      setClosedSessions(
        getLatestClosedSessions(response.data.result.filter(
          (s) => s.status === "CLOSED"
        ), 2)
      );
      setupCurrentSession(response.data.result);
    } else {
      // TODO: Manage Exception Here
    }

    setIsLoading((prevState) => ({
      ...prevState,
      getAllSessions: { value: false },
    }));
  };
  
  function getLatestClosedSessions(sessions, size = 2) {
    if (!sessions || sessions.length === 0) return [];

    return sessions.reduce((acc, current) => {
      // Insérer le nouvel élément au bon endroit
      const index = acc.findIndex(
        (item) => new Date(item.createdAt) < new Date(current.createdAt)
      );

      if (index === -1) {
        // Ajouter à la fin si c'est le plus ancien
        if (acc.length < size) acc.push(current);
      } else {
        // Insérer à la bonne position
        acc.splice(index, 0, current);
        // Garder seulement les N premiers éléments
        if (acc.length > size) acc.pop();
      }

      return acc;
    }, []);
  }
  
  const setupCurrentSession = async (_sessionsList) => {
    if (_sessionsList.length !== 0) {
      setCurrentSession(getLatestSession(_sessionsList.filter((s) => s.status === 'OPENED')));
    } else setCurrentSession(null);
  };

  const getLatestSession = (sessions) => {
    if (!sessions || sessions.length === 0) return null;
    
    return sessions.reduce((latest, current) => {
        const currentDate = new Date(current.createdAt);
        const latestDate = new Date(latest.createdAt);
        return currentDate > latestDate ? current : latest;
    });
  }
  
  useEffect(() => {
    if (bannerData) {
      getAllSessions();
    }
  }, [bannerData]);

  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <div>
      <BannerSection
        mixedSlides={bannerData}
        sessionsList={sessionsList}
        currentSession={currentSession}
        isLoading={isLoading}
      />
      <BottomBannerSection />
      <WhatIsUtrains currentSession={currentSession} />
      <WhyUsSection />
      <AboutInstructor />
      <AdvantagesSection />

      <MarketingSection currentSession={currentSession} />

      <div className="edu-expart-area expert-area-1 utrains-advantage-section-gap">
        <div className="container edublink-animated-shape">
          <div
            className="section-title section-center"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <span className="pre-title color-secondary">Advantages</span>
            <h2 className="title">
              <span className="text-gradient">What if you wanted </span> to pay
              other trainers <br /> for each of these job skills?
            </h2>
            <span className="shape-line">
              <i className="icon-19"></i>
            </span>
            <p>
              This is the list of computer IT skills and their corresponding
              fees if you choose <br /> to take them individually on other
              platforms. As a bonus, we coach you on how to <br /> successfully
              search for a job. All this at a very low cost.
            </p>
          </div>

          <div className="row g-5">
            <div className="col-lg-6 col-md-6">
              <div
                className="expert-progressbar"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div className="single-progressbar">
                  <p className="progress-title">Linux Administrator</p>
                  <div className="progressbar-one"></div>
                </div>
                <div className="single-progressbar">
                  <p className="progress-title">DevOps</p>
                  <div className="progressbar-two"></div>
                </div>
                <div className="single-progressbar">
                  <p className="progress-title">Cloud Engineer</p>
                  <div className="progressbar-three"></div>
                </div>
                <div className="single-progressbar">
                  <p className="progress-title">RedHat Exam Preparation</p>
                  <div className="progressbar-four"></div>
                </div>
                <div className="single-progressbar last-ber">
                  <p className="progress-title">Coaching for Job search</p>
                  <div className="progressbar-four"></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
              <div className="expert-section-heading">
                <div
                  className="section-title section-left"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <p className="text-center fs-1 discount">Discount</p>
                  <h5 className="utrains-headline e-animated e-hide-highlightt d-flex justify-content-center">
                    <span className="utrains-headline-plain-text utrains-headline-text-wrapper">
                      Don't spend{" "}
                    </span>
                    <span className="utrains-headline-dynamic-wrapper utrains-headline-text-wrapper">
                      <span className="utrains-headline-dynamic-text utrains-headline-text-active">
                        &nbsp; &nbsp; $15,000 &nbsp; &nbsp;
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 150"
                        preserveAspectRatio="none"
                      >
                        <path d="M497.4,23.9C301.6,40,155.9,80.6,4,144.4"></path>
                        <path d="M14.1,27.6c204.5,20.3,393.8,74,467.3,111.7"></path>
                      </svg>
                    </span>
                    <span className="utrains-headline-plain-text utrains-headline-text-wrapper">
                      {" "}
                      anymore!
                    </span>
                  </h5>

                  <div className="text-center d-flex flex-column justify-content-center align-items-center">
                    <div className="mb-5 text-center">
                      Get a discount of more than&nbsp; &nbsp;
                      <strong>80%</strong> and save{" "}
                      <span style={{ color: "#ff0000" }}>
                        &nbsp; &nbsp;<strong>$12,000&nbsp; &nbsp;</strong>
                      </span>
                      . Learn all these skills with only{" "}
                      <strong>
                        <span style={{ color: "var(--color-primary)" }}>
                          $3,000
                        </span>
                      </strong>
                    </div>

                    <div
                      className="banner-btn"
                      data-sal-delay="400"
                      data-sal="slide-up"
                      data-sal-duration="1000"
                    >
                      <a
                        href="https://fromzerototechhero.com/"
                        className="edu-btn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Join Our Free Masterclass<i className="icon-4"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Brand Area  --> */}

      <NextSessionSection
        currentSession={currentSession}
        closedSessions={closedSessions}
      />

      {/* <!--=====================================-->
        <!--=       WHAT YOU NEED TO JOIN US Area Start      	=-->
        <!--=====================================-->
        <!-- WHAT YOU NEED TO JOIN US Area  --> */}
      <div className="why-choose-area-1 section-gap-equal">
        <div className="container">
          <div className="row g-5 row--45">
            <div className="col-lg-6">
              <div className="why-choose-content">
                <div
                  className="section-title section-left"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <span className="pre-title pre-textsecondary">Join US</span>
                  <h2 className="title">
                    What you need <br /> to Join Us ?
                  </h2>
                  <span className="shape-line">
                    <i className="icon-19"></i>
                  </span>
                  <p>
                    Magna aliquaenim minim veniam quis nostrud exercitation
                    ullamco laborisLorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
                <div
                  className="features-list"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <div className="features-box color-secondary-style">
                    <div className="icon">
                      <i className="icon-81"></i>
                    </div>
                    <div className="content">
                      <h5 className="title">Notebook</h5>
                      <p>
                        You will need a notebook, to take notes for the course
                        and record your questions.
                      </p>
                    </div>
                  </div>
                  <div className="features-box color-primary-style">
                    <div className="icon">
                      <i className="icon-82"></i>
                    </div>
                    <div className="content">
                      <h5 className="title">Laptop</h5>
                      <p>
                        Follow the link below to view Laptop requirements and
                        recommendations for our program.
                      </p>
                    </div>
                  </div>
                  <div className="features-box color-extra05-style">
                    <div className="icon">
                      <i className="icon-83"></i>
                    </div>
                    <div className="content">
                      <h5 className="title">Passion</h5>
                      <p>
                        Essential in everything you do. It’s the fuel that gives
                        you the strength to keep going even when it’s hard.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-gallery">
                <div className="gallery-thumbnail">
                  <div className="thumbnail thumbnail-1">
                    <img
                      src="/assets/images/others/why-choose-01-1.jpg"
                      alt="Why Choose"
                    />
                  </div>
                  <div
                    className="thumbnail thumbnail-2"
                    data-parallax='{"x": 0, "y": -60}'
                  >
                    <img
                      src="/assets/images/others/why-choose-02-1.jpg"
                      alt="Why Choose"
                    />
                  </div>
                </div>
                <ul className="shape-group">
                  <li
                    className="shape-1"
                    data-sal-delay="500"
                    data-sal="fade"
                    data-sal-duration="200"
                  >
                    <img
                      src="/assets/images/about/shape-14.png"
                      alt="Shape Images"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End WHAT YOU NEED TO JOIN US Area  --> */}

      {/* <!--=====================================-->
        <!--=       	Have a Degree Area Start      	=-->
        <!--=====================================-->
        <!-- Have a Degree Area  --> */}
      <div className="benefits-area-1">
        <div className="benefits-wrapper">
          <div className="benefits-wrap-one"></div>
          <div className="benefits-wrap-two">
            <div className="process-gallery edublink-animated-shape">
              <div className="gallery-thumbnail">
                <div className="thumbnail thumbnail-1">
                  <img
                    src="/assets/images/others/benefit-img-01-1.jpg"
                    alt="Why Choose"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container edublink-animated-shape benefits-content-box">
            <div className="row">
              <div className="col-lg-6">
                <div className="process-content">
                  <div
                    className="section-title section-left"
                    data-sal-delay="100"
                    data-sal="slide-up"
                    data-sal-duration="1000"
                  >
                    <span className="pre-title color-secondary">
                      {" "}
                      Expertise
                    </span>
                    <h2 className="title">
                      Do I need to have{" "}
                      <span className="color-secondary">
                        <br />a Degree or Knowledge <br />{" "}
                      </span>{" "}
                      in Computer Sciences ?
                    </h2>
                    <span className="shape-line">
                      <i className="icon-19"></i>
                    </span>
                    <p>
                      Our training program takes people from various backgrounds
                      and different study levels and molds them into IT
                      professionals ready for work ! You don’t need any previous
                      computer knowledge to take this course. In 6 months, we
                      will take you from zero to hero in the skills we teach.
                    </p>
                  </div>
                  <div
                    className="features-wrapper"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="1000"
                  >
                    <ul className="features-list">
                      <li>
                        No former experience in computer science is needed !!
                      </li>
                      <li>Personal Trainer</li>
                      <li>Flexible Classes</li>
                    </ul>
                  </div>
                  {/* <div className="features-btn" data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">
                                    <Link to="/course_one" className="edu-btn">Learn More <i className="icon-4"></i></Link>
                                </div> */}
                  <ul className="shape-group">
                    <li
                      className="shape-1"
                      data-sal-delay="500"
                      data-sal="fade"
                      data-sal-duration="200"
                    >
                      <span></span>
                    </li>
                    <li
                      className="shape-2 scene"
                      data-sal-delay="500"
                      data-sal="fade"
                      data-sal-duration="200"
                    >
                      <img
                        data-depth="2"
                        src="/assets/images/counterup/shape-02.png"
                        alt="Shape Images"
                      />
                    </li>
                    <li
                      className="shape-3 scene"
                      data-sal-delay="500"
                      data-sal="fade"
                      data-sal-duration="200"
                    >
                      <img
                        data-depth="-2"
                        src="/assets/images/cta/shape-14.png"
                        alt="Shape Images"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- End Why Choose Area  --> */}

      {/* <OngoingSession/> */}

      {/* <!--=====================================-->
        <!--=       Testimonial Area Start      =-->
        <!--=====================================-->
        <!-- Start Testimonial Area  testimonial-area-18 testimonial-area-1  --> */}
      <div className="testimonial-area-5 utrains-testimonials-gap-v">
        <div className="container">
          <div className="row g-lg-5">
            <div className="col-lg-5">
              <div className="testimonial-heading-area">
                <div
                  className="section-title section-left"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <span className="pre-title">Testimonials</span>
                  <h2 className="title">A lot of Happy Students</h2>
                  <span className="shape-line">
                    <i className="icon-19"></i>
                  </span>
                  <p>
                    They are the pride of a growing value that we cease to
                    offer. Thanks to you, we're growing together.
                  </p>
                  {/* <Link to="/testimonials" className="edu-btn btn-large">
                    View All<i className="icon-4"></i>
                  </Link> */}
                  <div
                    className="banner-btn"
                    data-sal-delay="400"
                    data-sal="slide-up"
                    data-sal-duration="1000"
                  >
                    <a href="/testimonials" className="edu-btn">
                      View All<i className="icon-4"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="swiper-testimonial-slider-wrapper swiper testimonial-coverflow">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonial-grid">
                      {/* <div className="thumbnail">
                        <img
                          src="/assets/images/testimonial/testimonial-01.png"
                          alt="Testimonial"
                        />
                        <span className="qoute-icon">
                          <i className="icon-26"></i>
                        </span>
                      </div> */}
                      <div className="content">
                        <p>
                          Hello Prof, I hope you and your loved ones are doing
                          well. I am from the January 2022 session. I just
                          wanted to let you know that I have received an offer
                          as a Linux system administrator. I can never thank you
                          enough, because you have changed my life. Thank you
                          for everything you have done for us. May God bless you
                          infinitely.
                        </p>
                        <div className="rating-icon">
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                        </div>
                        {/* <h5 className="title">Ray Sanchez</h5>
                        <span className="subtitle">Student</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-grid">
                      {/* <div className="thumbnail">
                        <img
                          src="/assets/images/testimonial/testimonial-02.png"
                          alt="Testimonial"
                        />
                        <span className="qoute-icon">
                          <i className="icon-26"></i>
                        </span>
                      </div> */}
                      <div className="content">
                        <p>
                          Hi Prof, I hope you’re doing well! Gloria here from
                          your August session :) I just received a verbal offer
                          and am waiting on the paperwork. I’m excited and a bit
                          anxious! Since Monday is a holiday, I’d like to submit
                          my background check on UTRAINS today. How does the
                          process work? Also, thank you for this
                          class—everything is starting to make sense. Your
                          lessons and videos are amazing, and I’m really
                          enjoying it so far.
                        </p>
                        <div className="rating-icon">
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                        </div>
                        {/* <h5 className="title">Thomas Lopez</h5>
                        <span className="subtitle">Designer</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-grid">
                      {/* <div className="thumbnail">
                        <img
                          src="/assets/images/testimonial/testimonial-03.png"
                          alt="Testimonial"
                        />
                        <span className="qoute-icon">
                          <i className="icon-26"></i>
                        </span>
                      </div> */}
                      <div className="content">
                        <p>
                          Hello teacher, how are you? I finally started work on
                          Tuesday. It's really relaxing, we use everything you
                          showed us in class. I don't have too many difficulties
                          with dancing. However, I sent you a few people
                          recently for classes. Have a good day, thank you very
                          much.
                        </p>
                        <div className="rating-icon">
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                        </div>
                        {/* <h5 className="title">Amber Page</h5>
                        <span className="subtitle">Developer</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="testimonial-grid">
                      {/* <div className="thumbnail">
                        <img
                          src="/assets/images/testimonial/testimonial-04.png"
                          alt="Testimonial"
                        />
                        <span className="qoute-icon">
                          <i className="icon-26"></i>
                        </span>
                      </div> */}
                      <div className="content">
                        <p>
                          Hi Uncle, it's me Femi. Thank you so much for
                          everything that you've done for me. Your course has
                          truly changed my life. I also have my little brother
                          Tosin, who is also interested in taking the course as
                          well and I wanted to know when the next session would
                          be. Thanks and God bless.
                        </p>
                        <div className="rating-icon">
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                          <i className="icon-23"></i>
                        </div>
                        {/* <h5 className="title">Robert Tapp</h5>
                        <span className="subtitle">Content Creator</span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CTABannerSection currentSession={currentSession} />

      {/* <!--=====================================-->
        <!--=       Job Title Area Start      	=-->
        <!--=====================================-->
        <!-- Start Job Title Area  --> */}
      <div
        className="edu-categorie-area categorie-area-3 edu-section-gap bg-image"
        id="categories"
      >
        <div className="container">
          <div
            className="section-title section-center"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
          >
            <span className="pre-title pre-textsecondary">Job Titles </span>
            <h2 className="title">
              With <span className="color-primary">our training </span> you will
              gain valuable skillsets that allow you to hold one of the
              following job titles.
            </h2>
            <span className="shape-line">
              <i className="icon-19"></i>
            </span>
          </div>
          <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4">
            {/* ok */}
            <div
              className="col"
              data-sal-delay="100"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-primary-style">
                <div className="icon">
                  <i className="icon-9"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Automation Engineer
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="250"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-tertiary-style">
                <div className="icon">
                  <i className="icon-12"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Linux Administrator
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="300"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra02-style">
                <div className="icon">
                  <i className="icon-13"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Cloud Support Engineer
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="100"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra07-style">
                <div className="icon design-pencil-icon">
                  <i className="icon-42"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Site Reliability Engineer
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-secondary-style">
                <div className="icon">
                  <i className="icon-10"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    DevOps Engineer
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra04-style">
                <div className="icon">
                  <i className="icon-11"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Software Engineer
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="150"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra06-style">
                <div className="icon">
                  <i className="icon-14"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    System Analyst
                  </h5>
                </div>
              </div>
            </div>

            {/* ok */}
            <div
              className="col"
              data-sal-delay="200"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra03-style">
                <div className="icon laptop-icon">
                  <i className="icon-16"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    CI/CD Engineer
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col"
              data-sal-delay="250"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra01-style">
                <div className="icon">
                  <i className="icon-17"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Infrastructure Engineer
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col"
              data-sal-delay="300"
              data-sal="slide-up"
              data-sal-duration="800"
            >
              <div className="categorie-grid categorie-style-3 color-extra05-style">
                <div className="icon">
                  <i className="icon-43"></i>
                </div>
                <div className="content">
                  <h5 className="title" style={{ cursor: "default" }}>
                    Security DevOps (DevSecOps)
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Job Title Area  --> */}

      <CompaniesStudentSection />

      <GetInTouchSection />

      {/* <BlogSection/> */}
    </div>
  );
}

export default Home
