import { CALL_API } from '../utils/HTTP';
import backendUrls from '../utils/BackendUrls';

const FeedbackService = {
  sendfeedback: async function (data) {
      const url = backendUrls.MAILER.SENDFEEDBACK, secure = false, cache = false;
      return await CALL_API("POST", url, data, secure, cache);
  }
};

export default FeedbackService;