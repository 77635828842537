
function BottomBannerSection() {

    return (
      <>
        <div className="features-area-2">
          <div className="container">
            <div className="features-grid-wrap">
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img
                    className="svgInject"
                    src="/assets/images/animated-svg-icons/online-class.svg"
                    alt="animated icon"
                  />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span>13</span> Cohort of Students
                  </h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img
                    className="svgInject"
                    src="/assets/images/animated-svg-icons/instructor.svg"
                    alt="animated icon"
                  />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span>Top</span>Instructors
                  </h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon certificate">
                  <img
                    className="svgInject"
                    src="/assets/images/animated-svg-icons/certificate.svg"
                    alt="animated icon"
                  />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span>Online</span>Certifications
                  </h5>
                </div>
              </div>
              <div className="features-box features-style-2 edublink-svg-animate">
                <div className="icon">
                  <img
                    className="svgInject"
                    src="/assets/images/animated-svg-icons/user.svg"
                    alt="animated icon"
                  />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span>6000</span>Members
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default BottomBannerSection
