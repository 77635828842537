import React from 'react'
import { Link } from 'react-router-dom'
import FAQAccordion from './FAQAccordion';
import CTABannerSection from '../Home/CTABannerSection';

function FaqContent() {
  return (
    <div>
      <div className="edu-breadcrumb-area">
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="page-title">
              <h1 className="title">Frequently Asked Questions</h1>
            </div>
            <ul className="edu-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="separator">
                <i className="icon-angle-right"></i>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Frequently Asked Questions
              </li>
            </ul>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-1">
            <span></span>
          </li>
          <li className="shape-2 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-13.png"
              alt="shape"
            />
          </li>
          <li className="shape-3 scene">
            <img
              data-depth="-2"
              src="assets/images/about/shape-15.png"
              alt="shape"
            />
          </li>
          <li className="shape-4">
            <span></span>
          </li>
          <li className="shape-5 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-07.png"
              alt="shape"
            />
          </li>
        </ul>
      </div>

      <h2 className="title" style={{ textAlign: "center", marginTop: "50px" }}>
        Have any questions?
      </h2>

      <section className="edu-section-gap faq-page-area edu-section-gap-custom">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                        <div className="faq-page-nav">
                            <h3 className="title">Questions By This Category</h3>
                            <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eius mod ex tempor incididunt labore.</p>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#gn-ques" type="button" role="tab" aria-selected="true">General Questions</button>
                                </li>
                                 <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#rg-ques" type="button" role="tab" aria-selected="false">Regular Questions</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#ad-ques" type="button" role="tab" aria-selected="false">Advanced questions</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#com-policy" type="button" role="tab" aria-selected="false">Company Policies</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#pay-option" type="button" role="tab" aria-selected="false">Payment Options</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#terms-condition" type="button" role="tab" aria-selected="false">Terms & Conditions</button>
                                </li> 
                            </ul>
                        </div>
                    </div> */}
            <div className="col-lg-12">
              <div
                className="tab-content faq-page-tab-content"
                id="faq-accordion"
              >
                <div
                  className="tab-pane fade show active"
                  id="gn-ques"
                  role="tabpanel"
                >
                  <FAQAccordion />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="tab-content faq-page-tab-content" id="faq-accordion">
            <div
              className="tab-pane fade show active"
              id="gn-ques"
              role="tabpanel"
            ></div>
          </div>
        </div>
      </section>

      {/* <CTABannerSection /> */}
    </div>
  );
}

export default FaqContent
