import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../utils/helpers";


function SessionDetails({ fileUrl, data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [mediaType, setMediaType] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [weeksBetween, setWeeksBetween] = useState("");

  // Deux dates
  const date1 = dayjs(data?.startDate);
  const date2 = dayjs(data?.endDate);
 
  const handleTogglePlay = () => {
    // setIsPlaying(prevIsPlaying => !prevIsPlaying);
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    setIsButtonVisible(false); // On cache le bouton quand on clique pour lire
  };
  const handlePause = () => {
    setIsButtonVisible(true); // On réaffiche le bouton lorsque la vidéo est en pause
  };

  useEffect(() => {
    // Calculer le nombre de semaines entre les deux dates
    setWeeksBetween(date2.diff(date1, "week"));
  }, [data]);

  // useEffect(() => {
  //   const fileExtension = fileUrl?.split(".").pop();
  //   console.log({fileExtension})
  //   if (
  //     fileExtension === "mp4" ||
  //     fileExtension === "avi" ||
  //     fileExtension === "jfrem"
  //   ) {
  //     setMediaType("video");
  //     setMediaUrl(fileUrl);
  //   } else if (
  //     fileExtension === "jpeg" ||
  //     fileExtension === "jpg" ||
  //     fileExtension === "png"
  //   ) {
  //     setMediaType("image");
  //     setMediaUrl(fileUrl);
  //   } else {
  //     console.log("Format de fichier non pris en charge");
  //   }
  // }, [fileUrl]);

  useEffect(() => {
    const fileExtension = fileUrl?.split(".").pop();
    // console.log({ fileExtension });

    if (fileUrl?.includes("youtube.com") || fileUrl?.includes("youtu.be")) {
      setMediaType("video");
      setMediaUrl(fileUrl);
    } else if (
      fileExtension === "mp4" ||
      fileExtension === "avi" ||
      fileExtension === "jfrem"
    ) {
      setMediaType("video");
      setMediaUrl(fileUrl);
    } else if (
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "png"
    ) {
      setMediaType("image");
      setMediaUrl(fileUrl);
    } else {
      console.log("Format de fichier non pris en charge");
    }
  }, [fileUrl]);

  return (
    <div className="col-lg-4">
      <div className="shadow course-sidebar-3 sidebar-top-position sidebar-top-position-custom">
        <div className="edu-course-widget widget-course-summery">
          <div className="inner">
            <div
              className={`thumbnail ${mediaType === "video" ? "ns-video" : " "
                }`}
            >
              {mediaType === "video" && (
                <div>
                  <iframe
                    className="ns-video-area"
                    title="YouTube Video Player"
                    width="100%"
                    height="250px"
                    // src={`${mediaUrl}?autoplay=${isPlaying ? 1 : 0}`}                  
                    src={data?.coverVideo}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  // onClick={handlePause}
                  ></iframe>
                  {/* {isButtonVisible && (
                    <Link
                      onClick={handleTogglePlay}
                      className="play-btn video-popup-activation"
                    >
                      {isPlaying ? "Pause" : <i className="icon-18"></i>}
                    </Link>
                  )} */}
                </div>
              )}

              {mediaType === "image" && <img src={mediaUrl} alt="Img-1" />}
            </div>
            <div className="content">
              <h4 className="widget-title">Session Includes:</h4>
              <ul className="course-item">
                <li>
                  <span className="label">
                    <i className="icon-61"></i>Duration:
                  </span>
                  <span className="value">{weeksBetween} week(s)</span>
                </li>
                <li>
                  <span className="label">
                    <i className="icon-63"></i>Start date:
                  </span>
                  <span className="value">{formatDate(data?.startDate)}</span>
                </li>
                <li>
                  <span className="label">
                    <i className="icon-62"></i>End date:
                  </span>
                  <span className="value">{formatDate(data?.endDate)}</span>
                </li>
                {/* <li>
                                <span className="label">
                                    <img className="svgInject" src="/assets/images/svg-icons/books.svg" alt="book icon"/>
                                    Lessons:</span>
                                <span className="value">8</span>
                            </li> */}
                {/* <li>
                                <span className="label"><i className="icon-63"></i>Enrolled:</span>
                                <span className="value">65 students</span>
                            </li> */}
                <li>
                  <span className="label">
                    <i className="icon-59"></i>Language:
                  </span>
                  <span className="value">English</span>
                </li>
                {/* <li>
                  <span className="label">
                    <i className="icon-64"></i>Certificate:
                  </span>
                  <span className="value">No</span>
                </li> */}
              </ul>
              {/* <div className="read-more-btn">
                <Link to={data?.paymentLink} className="edu-btn">
                  Enroll Now <i className="icon-4"></i>
                </Link>
              </div> */}
              {/* <div className="share-area">
                            <h4 className="title">Share On:</h4>
                            <ul className="social-share">
                                <li><Link to="#"><i className="icon-facebook"></i></Link></li>
                                <li><Link to="#"><i className="icon-twitter"></i></Link></li>
                                <li><Link to="#"><i className="icon-linkedin2"></i></Link></li>
                                <li><Link to="#"><i className="icon-youtube"></i></Link></li>
                            </ul>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionDetails;
