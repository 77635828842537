const baseUrl = 'https://jsonplaceholder.typicode.com/users';
const authenticationBaseUrl = process.env.REACT_APP_AUTHENTICATION_SERVICE;

export const accountingBaseUrlSecure = process.env.REACT_APP_ACCOUNTING_SERVICE + '/api/v1/secure/';
export const MEDIA_URL = authenticationBaseUrl + "/uploads/";

const lmsBaseURL = process.env.REACT_APP_LMS_COURSE_SERVICE;
const lmsApiURL = process.env.REACT_APP_LMS_COURSE_SERVICE + '/api';

const BackendUrls = {
  baseUrl: baseUrl,
  uploadFileURL: `${lmsApiURL}/upload`,
  lmsBaseURL: lmsBaseURL,
  lmsApiURL: lmsApiURL,

  USER: {
    CONFIRM_DISOUNT: accountingBaseUrlSecure + "user/discount-intent/confirm",
    SUCCESS_DISOUNT:
      accountingBaseUrlSecure + "user/information-about-success-subcription",
    ALL_USER: lmsApiURL + "/user/list",
    ALL: lmsApiURL + "/user/list",
    SEND: `${lmsApiURL}/send-mail`,

    CREATE: lmsApiURL + "/user/create",
    UPDATE: lmsApiURL + "/user/edit",
    DELETEE: lmsApiURL + "/user/delete",
    LOGIN: lmsApiURL + "/login",
    DELETE: lmsApiURL + "/enrollments/delete",
  },

  MAILER: {
    SEND: lmsApiURL + "/send-mail",
    SENDFEEDBACK: lmsApiURL + "/send-mail",
  },

  HOMEPAGE: {
    BANNER_INFO: lmsApiURL + "/unsecure/home-banner",
  },

  COURSE: {
    ALL: lmsApiURL + "/unsecure/list-course",
    DETAILS: lmsApiURL + "/unsecure/course-details-public",
  },
  COURSE_SESSION: {
    DETAILS: lmsApiURL + "/limited-course-content",
  },

  SESSION: {
    ALL: lmsApiURL + "/unsecure/session-list",
    DETAILS: lmsApiURL + "/unsecure/course-details-public",
  },
};
export default BackendUrls;