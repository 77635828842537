import Footer from "../components/common/Footer";
import BreadCrumbApp from "../components/common/BreadCrumb";
import LoadScriptAfterReact from "../utils/LoadScriptAfterReact";
import Header from '../components/common/Header';
import ProgressParent from "../components/ProgressParent/ProgressParent";

function MainLayout(props) {

    /**
    * States
    */
    const { children } = props;

    /**
     * Comportments
     */
    const content = Array.isArray(children)
    ? children.find((child) => child.type === Content)
    : children.type === Content
    ? children
    : null;
    // const breadcrumb = Array.isArray(children)
    // ? children.find((child) => child.type === BreadCrumb)
    // : children.type === BreadCrumb
    // ? children
    // : null;
    // const header = Array.isArray(children)
    // ? children.find((child) => child.type === Header)
    // : children.type === Header
    // ? children
    // : null;
    // const sidebar = Array.isArray(children)
    // ? children.find((child) => child.type === SideBar)
    // : children.type === SideBar
    // ? children
    // : null;

    return (
        <>
            <div>
                <div id="main-wrapper" className="main-wrapper">
                    <Header/>
                    <BreadCrumbApp />
                    {
                        content
                            ? content.props.children
                            : "Please define the MainLayout.Content slot in this component"
                    }
                    <ProgressParent/>
                    <Footer/>
                </div>
            </div>
            <LoadScriptAfterReact />
        </>
    )
}

// SubComponents to simulate slots
const Header_ = () => null;
const BreadCrumb = () => null;
const Content = () => null;

// We introduce the subcomponents in the current component
MainLayout.Header = Header_;
MainLayout.BreadCrumb = BreadCrumb;
MainLayout.Content = Content;

export default MainLayout
  