/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatDate } from "../../../utils/helpers";

function NextSessionSection({ currentSession, closedSessions }) {
  return (
    <>
      <div className="edu-blog-area blog-area-3 edu-section-gap sales-coach-blog">
        <div className="container">
          <ul className="blog-section-title">
            <li>
              <div
                className="section-title section-left sal-animate"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <span className="pre-title">Next Session</span>
                <h2 className="title">Access Our new Session</h2>
                <span className="shape-line">
                  <i className="icon-19"></i>
                </span>
              </div>
            </li>
          </ul>
          <div className="row g-5">
            {currentSession && (
              <div
                className="col-lg-6"
                data-sal-delay="100"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div className="edu-blog blog-style-2 blog-style-10">
                  <div className="inner">
                    <div className="thumbnail">
                      <a
                        href={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
                      >
                        <img
                          style={{
                            height: "370px",
                          }}
                          className="object-cover "
                          src={currentSession?.coverPicture}
                          alt={currentSession?.coverPicture}
                        />
                      </a>
                    </div>
                    <div className="content">
                      <h5 className="pt-5 title">
                        <a
                          href={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
                        >
                          {currentSession?.title}
                        </a>
                      </h5>

                      <div className="mt-3 category-wrap">
                        <a
                          href={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
                          className="blog-category"
                        >
                          Enroll Now
                        </a>
                      </div>

                      <ul className="mt-4 blog-meta">
                        <li>
                          <i className="icon-27"></i>
                          {`Start Date : ` +
                            formatDate(currentSession?.startDate, 2)}
                        </li>
                        <li>
                          <i className="icon-27"></i>
                          {`End Date : ` +
                            formatDate(currentSession?.endDate, 2)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {closedSessions && closedSessions.length !== 0
              ? closedSessions?.map((session__) => (
                  <div
                    className="col-lg-3"
                    data-sal-delay="100"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <div className="edu-blog blog-style-2 blog-style-10">
                      <div className="inner">
                        <div className="thumbnail">
                          {/* <a href="#"> */}
                          <img
                            src={session__?.coverPicture}
                            // src="assets/images/blog/blog-40.jpg"
                            alt={session__?.coverPicture}
                            // style={{
                            //   height: "370px",
                            //   // width: "570px",
                            // }}
                          />
                          {/* </a> */}
                        </div>
                        <div className="content">
                          <div className="category-wrap">
                            <a
                              // href="#"
                              className="blog-category small-category session-block"
                            >
                              Closed
                            </a>
                          </div>
                          <h5 className="title">
                            <a
                            // href="#"
                            >
                              {session__?.title}
                            </a>
                          </h5>
                          <ul className="blog-meta">
                            <li>
                              <i className="icon-27"></i>
                              {`Start Date : ` +
                                formatDate(session__?.startDate, 2)}
                            </li>
                            <li>
                              <i className="icon-27"></i>
                              {`End Date : ` +
                                formatDate(session__?.endDate, 2)}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default NextSessionSection;