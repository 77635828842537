// FeedbackForm.js
// import React, { useState } from 'react';

// const FeedbackForm = ({ title, description, options, onSubmit }) => {
//   const [email, setEmail] = useState('');
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [feedback, setFeedback] = useState('');

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit({ email, selectedOption, feedback });
//   };

//   return (
//     <div className="contact-form form-style-2">
//       <div className="section-title">
//         <h4 className="title">{title}</h4>
//         <p>{description}</p>
//       </div>
//       <form className="rnt-contact-form rwt-dynamic-form" onSubmit={handleSubmit}>
//         <div className="row row--10">
//           <div className="form-group col-12">
//             <input
//               type="email"
//               name="contact-email"
//               id="contact-email"
//               placeholder="Enter your email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>
//           <div className="form-group col-12">
//             <p className="form-group" style={{ margin: '30px 0' }}>
//               Select the field in which you want to make a suggestion
//             </p>
//             {options.map((option, index) => (
//               <div key={index} className="edu-form-check">
//                 <input
//                   type="radio"
//                   id={`suggestion-feedback${index + 1}`}
//                   name="suggestion-feedback"
//                   checked={selectedOption === option}
//                   onChange={() => setSelectedOption(option)}
//                 />
//                 <label htmlFor={`suggestion-feedback${index + 1}`}>{option}</label>
//               </div>
//             ))}
//           </div>
//           <div className="form-group col-12">
//             <textarea
//               name="contact-message"
//               id="contact-message"
//               cols="30"
//               rows="2"
//               placeholder="Your feedback"
//               value={feedback}
//               onChange={(e) => setFeedback(e.target.value)}
//             ></textarea>
//           </div>
//           <div className="form-group col-12">
//             <button className="rn-btn edu-btn btn-medium submit-btn" type="submit">
//               Submit Message <i className="icon-4"></i>
//             </button>
//           </div>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default FeedbackForm;
import React, { useState } from 'react';
import FeedbackService from '../../../services/FeedbackService';

const FeedbackForm = ({ title, description, options }) => {
  const [email, setEmail] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [errors, setErrors] = useState({});

  // Fonction de validation
  const validateField = (field, value) => {
    switch (field) {
      case 'email':
        if (!value || !/\S+@\S+\.\S+/.test(value)) {
          return 'A valid email is required.';
        }
        return null;
      case 'selectedOption':
        if (!value) {
          return 'Please select a category.';
        }
        return null;
      case 'feedback':
        if (!value || value.trim().length < 5) {
          return 'Feedback must be at least 5 characters long.';
        }
        return null;
      default:
        return null;
    }
  };

  const handleFieldChange = (field, value) => {
    // Met à jour les valeurs
    if (field === 'email') setEmail(value);
    if (field === 'selectedOption') setSelectedOption(value);
    if (field === 'feedback') setFeedback(value);

    // Supprime les erreurs pour le champ en cours d'édition
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validateField(field, value),
    }));
  };

  const validate = ({ email, selectedOption, feedback }) => {
    return {
      email: validateField('email', email),
      selectedOption: validateField('selectedOption', selectedOption),
      feedback: validateField('feedback', feedback),
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prépare les données
    const contactData = { email, selectedOption, feedback };

    // Effectue la validation
    const validationErrors = validate(contactData);
    const filteredErrors = Object.keys(validationErrors).reduce((acc, key) => {
      if (validationErrors[key]) {
        acc[key] = validationErrors[key];
      }
      return acc;
    }, {});

    setErrors(filteredErrors);

    // Arrête si validation échoue
    if (Object.keys(filteredErrors).length > 0) {
      return;
    }

    setLoading(true);
    setResponseMessage(null);

    try {
      // Appel du service pour envoyer les données
      const response = await FeedbackService.sendfeedback(contactData);

      // Si tout se passe bien
      setResponseMessage({ type: 'success', text: response.message || 'Feedback sent successfully!' });
      setEmail('');
      setSelectedOption(null);
      setFeedback('');
    } catch (error) {
      // Gestion des erreurs
      setResponseMessage({
        type: 'error',
        text: error.message || 'Failed to send feedback. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form form-style-2">
      <div className="section-title">
        <h4 className="title">{title}</h4>
        <p>{description}</p>
      </div>
      <form className="rnt-contact-form rwt-dynamic-form" onSubmit={handleSubmit}>
        <div className="row row--10">
          {/* Champ email */}
          <div className="form-group col-12">
            <input
              type="email"
              name="contact-email"
              id="contact-email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
            {errors.email && <small className="form-text text-danger">{errors.email}</small>}
          </div>

          {/* Options de catégorie */}
          <div className="form-group col-12">
            <p className="form-group" style={{ margin: '30px 0' }}>
              Select the field in which you want to make a suggestion
            </p>
            {options.map((option, index) => (
              <div key={index} className="edu-form-check">
                <input
                  type="radio"
                  id={`suggestion-feedback${index + 1}`}
                  name="suggestion-feedback"
                  checked={selectedOption === option}
                  onChange={() => handleFieldChange('selectedOption', option)}
                />
                <label htmlFor={`suggestion-feedback${index + 1}`}>{option}</label>
              </div>
            ))}
            {errors.selectedOption && (
              <small className="form-text text-danger">{errors.selectedOption}</small>
            )}
          </div>

          {/* Champ de feedback */}
          <div className="form-group col-12">
            <textarea
              name="contact-message"
              id="contact-message"
              cols="30"
              rows="2"
              placeholder="Your feedback"
              value={feedback}
              onChange={(e) => handleFieldChange('feedback', e.target.value)}
            ></textarea>
            {errors.feedback && <small className="form-text text-danger">{errors.feedback}</small>}
          </div>

          {/* Bouton de soumission */}
          <div className="form-group col-12">
            <button
              className="rn-btn edu-btn btn-medium submit-btn"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Submit Message'} <i className="icon-4"></i>
            </button>
          </div>

          {/* Message de retour */}
          {responseMessage && (
            <div
              className={`form-response ${
                responseMessage.type === 'success' ? 'success' : 'error'
              }`}
              style={{
                marginTop: '20px',
                color: responseMessage.type === 'success' ? 'green' : 'red',
              }}
            >
              {responseMessage.text}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;