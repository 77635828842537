import React from 'react';

function TestimonialNavigation() {
  return (
    <div className="swiper-navigation" >
      <div className="swiper-btn-nxt">
        <i className="icon-west"></i>
      </div>
      <div className="swiper-btn-prv">
        <i className="icon-east"></i>
      </div>
    </div>
  );
}

export default TestimonialNavigation;