import React from 'react'
import { Link } from 'react-router-dom'
import FaqArea from './FaqArea'

function FeedbackContent() {
  return (
    <div>
      <div className="edu-breadcrumb-area">
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="page-title">
              <h1 className="title">Feedback</h1>
            </div>
            <ul className="edu-breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="separator">
                <i className="icon-angle-right"></i>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Feedback
              </li>
            </ul>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-1">
            <span></span>
          </li>
          <li className="shape-2 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-13.png"
              alt="shape"
            />
          </li>
          <li className="shape-3 scene">
            <img
              data-depth="-2"
              src="assets/images/about/shape-15.png"
              alt="shape"
            />
          </li>
          <li className="shape-4">
            <span></span>
          </li>
          <li className="shape-5 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-07.png"
              alt="shape"
            />
          </li>
        </ul>
      </div>

      <FaqArea />

      <div className="remote-training-wrapper edu-cta-banner-area cta-banner-8 utrains-zindex-cta-feedback">
        <div className="container">
          <div className="edu-cta-banner">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div
                  className="section-title section-center"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <h2 className="title">
                    Access our Next Devops & IT Training Session launched by
                    Utrains
                  </h2>
                  <div cllassName="edu-btn btn-medium" assName="subs-button">
                    <Link to="/contact_us" className="edu-btn btn-medium">
                      Join now <i className="icon-4"></i>
                    </Link>
                
                  </div>
                </div>
              </div>
            </div>
            <ul className="shape-group">
              <li className="shape-01 scene">
                <img
                  data-depth="2.5"
                  src="assets/images/cta/shape-38.png"
                  alt="shape"
                />
              </li>
              <li className="shape-02 scene">
                <img
                  data-depth="-2.5"
                  src="assets/images/cta/shape-42.png"
                  alt="shape"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackContent
