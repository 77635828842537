
import React, { useState } from 'react';
import TestimonialTable1 from './TestimonialTable1';
import TestimonialTable2 from './TestimonialTable2';
import TestimonialTable3 from './TestimonialTable3';
import TestimonialTable4 from './TestimonialTable4';
import TestimonialTable5 from './TestimonialTable5';
import TestimonialTable6 from './TestimonialTable6';

const TabContent = ({ activeTab }) => {
  const [openAccordion, setOpenAccordion] = useState(activeTab); // État pour l'accordéon ouvert

  const getTabTitle = (tabId) => {
    switch (tabId) {
      case 'gn-ques':
        return 'Cloud DevOps Engineer';
      case 'rg-ques':
        return 'DevOps Engineer';
      case 'ad-ques':
        return 'Senior DevOps Engineer';
      case 'com-policy':
        return 'Software Engineer';
      case 'pay-option':
        return 'System Engineer';
      case 'terms-condition':
        return 'Site Reliability Engineer';
      default:
        return '';
    }
  };

  const getTabContent = (tabId) => {
    switch (tabId) {
      case 'gn-ques':
        return <TestimonialTable1 />;
      case 'rg-ques':
        return <TestimonialTable2 />;
      case 'ad-ques':
        return <TestimonialTable3 />;
      case 'com-policy':
        return <TestimonialTable4 />;
      case 'pay-option':
        return <TestimonialTable5 />;
      case 'terms-condition':
        return <TestimonialTable6 />;
      default:
        return null;
    }
  };

  const handleAccordionToggle = (tabId) => {
    setOpenAccordion((prevTab) => (prevTab === tabId ? null : tabId)); // Toggle l'état de l'accordéon
  };

  return (
    <div
      className={`tab-pane fade ${
        ['gn-ques', 'rg-ques', 'ad-ques', 'com-policy', 'pay-option', 'terms-condition'].includes(activeTab)
          ? 'show active'
          : ''
      }`}
      id={activeTab}
      role="tabpanel"
    >
      <div className="faq-accordion">
        <div className="accordion" id={`faq-accordion-${activeTab}`}>
          <div className="accordion-item">
            <h5 className="accordion-header">
              <button
                className={`accordion-button ${openAccordion === activeTab ? '' : 'collapsed'}`}
                type="button"
                aria-expanded={openAccordion === activeTab}
                aria-controls={`collapse-${activeTab}`}
                onClick={() => handleAccordionToggle(activeTab)}
              >
                {getTabTitle(activeTab)}
              </button>
            </h5>
            <div
              id={`collapse-${activeTab}`}
              className={`accordion-collapse collapse ${openAccordion === activeTab ? 'show' : ''}`}
              aria-labelledby={`heading-${activeTab}`}
              data-bs-parent={`#faq-accordion-${activeTab}`}
            >
              <div className="accordion-body">{getTabContent(activeTab)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabContent;