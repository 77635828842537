import React, { useState, useCallback, useEffect } from "react";
import usePageLoad from "../../../../hooks/usePageLoad";
import SkeletonLoader from "./SkeletonLoader";
import { formatDate } from "../../../../utils/helpers";
import { Link } from "react-router-dom";

const FadeSlider = ({
  slides = [],
  autoplayInterval = 0,
  isLoading = false,
  currentSession,
}) => {
  
  console.log('slides: ', slides);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isLoaded = usePageLoad();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isAutoPlaying, setIsAutoPlaying] = useState(!!autoplayInterval);

  const goToSlide = useCallback(
    (index) => {
      if (!isAnimating) {
        setIsAnimating(true);
        setCurrentIndex(index);
        setTimeout(() => setIsAnimating(false), 500);
      }
    },
    [isAnimating]
  );
  const mixedDefaultSlides = (
    <div className="hero-banner hero-style-1">
      <style>
        {`
          .hero-style-1::before {
            background-image: url(/assets/images/banner/BG-1.webp) !important;
          }
        `}
      </style>

      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-content">
              <h2
                className="title"
                data-sal-delay="100"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                {currentSession?.title}
                {/* Linux, AWS, and Devops training{" "} */}
              </h2>
              <br />
              <h1
                className="title"
                data-sal-delay="100"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                <span className="color-secondary">
                  Ready to Join Our Next Class?
                </span>
              </h1>
              <p
                data-sal-delay="200"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                This session will start on{" "}
                {formatDate(currentSession?.startDate, 2)}
                {/* April 20, 2024.{" "} */}
              </p>
              <div
                className="banner-btn"
                data-sal-delay="400"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                {currentSession ? (
                  <Link
                    to={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
                    className="edu-btn"
                  >
                    Join Our Free Masterclass <i className="icon-4"></i>
                  </Link>
                ) : null}
              </div>
              <ul className="shape-group">
                <li
                  className="shape-1 scene"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  <img
                    data-depth="2"
                    src="/assets/images/about/shape-13.png"
                    alt="Shape"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <div
                className="thumbnail"
                data-sal-delay="500"
                data-sal="slide-left"
                data-sal-duration="1000"
              >
                <img src="/assets/images/banner/girl-1.webp" alt="Girl Img" />
              </div>

              {/* <InstructorInfo/> */}

              <ul className="shape-group">
                <li
                  className="shape-1"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  <img
                    data-depth="1.5"
                    src="/assets/images/about/shape-15.png"
                    alt="Shape"
                  />
                </li>
                <li
                  className="shape-2 scene"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  <img
                    data-depth="-1.5"
                    src="/assets/images/about/shape-16.png"
                    alt="Shape"
                  />
                </li>
                <li
                  className="shape-3 scene"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  {/* <span data-depth="3" className="circle-shape"></span>*/}
                  <img
                    data-depth="1.6"
                    src="/assets/images/counterup/shape-08.png"
                    alt="Shape"
                  />
                </li>

                <li
                  className="shape-5 scene"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  <img
                    data-depth="1.5"
                    src="/assets/images/about/shape-13.png"
                    alt="Shape"
                  />
                </li>
                <li
                  className="shape-6 scene"
                  data-sal-delay="1000"
                  data-sal="fade"
                  data-sal-duration="1000"
                >
                  <img
                    data-depth="-2"
                    src="/assets/images/about/shape-18.png"
                    alt="Shape"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-7">
        <img src="/assets/images/about/h-1-shape-01.png" alt="Shape" />
      </div>
    </div>
  );
      
  const goToNext = useCallback(() => {
    if (currentIndex < slides.length - 1) {
      goToSlide(currentIndex + 1);
    } else {
      goToSlide(0);
    }
  }, [currentIndex, slides.length, goToSlide]);

  const goToPrevious = useCallback(() => {
    if (currentIndex > 0) {
      goToSlide(currentIndex - 1);
    }
  }, [currentIndex, goToSlide]);

  const renderSlide = (slide) => {
    if (typeof slide === "object" && slide.type === "image") {
      return (
        <a href={slide.link} alt={slide.link}>
          <img
            src={slide.url}
            alt={slide.alt || ""}
            className="img-fluid w-100 custom-h-100 object-fit-cover"
          />
        </a>
      );
    }
    if (typeof slide === "object" && slide.type === "html" ) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: slide.content,
          }}
        ></div>
      );
    }
    return null
    // return slide;
  };

  useEffect(() => {
    let interval;
    if (autoplayInterval && isAutoPlaying && slides.length > 1) {
      interval = setInterval(goToNext, autoplayInterval);
    }
    return () => clearInterval(interval);
  }, [autoplayInterval, isAutoPlaying, goToNext, slides.length]);

  // isLoading 
  return isLoading || isLoaded ? (
    <SkeletonLoader />
  ) : (
    <div
      className="position-relative w-100 custom-h-100"
      onMouseEnter={() => setIsAutoPlaying(false)}
      onMouseLeave={() => setIsAutoPlaying(!!autoplayInterval)}
    >
      <div className="overflow-hidden position-relative w-100 custom-h-100">
        {slides.length !== 0
          ? slides.map((slide, index) => (
              <div
                key={index}
                className={`position-absolute w-100 custom-h-100 fade ${
                  currentIndex === index ? "show" : ""
                }`}
                style={{ transition: "opacity 0.5s ease" }}
              >
                {renderSlide(slide)}
              </div>
            ))
          : mixedDefaultSlides}
      </div>

      {slides.length > 1 && (
        <div className="px-4 position-absolute top-50 start-0 end-0 d-flex justify-content-between translate-middle-y">
          <div
            onClick={goToPrevious}
            disabled={currentIndex === 0}
            className="custom-slide-controls custom-slide-prev"
            style={{ opacity: 0.8 }}
          >
            <i className="icon-west"></i>
          </div>
          <div
            onClick={goToNext}
            disabled={currentIndex === slides.length - 1}
            className="custom-slide-controls custom-slide-next "
            style={{ opacity: 0.8 }}
          >
            <i className="icon-east"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default FadeSlider;
