import { Link } from "react-router-dom";

function CompaniesStudentSection() {
  return (
    <>
      <div className="home-one-cta-two cta-area-1 utrains-cta-gap-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="brand-section-heading">
                <div
                  className="section-title section-left"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <span className="pre-title">Our Students</span>
                  <h2 className="title">
                    Companies where our students have worked 
                  </h2>
                  <span className="shape-line">
                    <i className="icon-19"></i>
                  </span>
                  <p>
                    On the left are the companies where our students work and
                    are the pride of their service companies.
                  </p>
                </div>
                {/* <Link className="edu-btn" to="/companies-students-worked">
                  View All <i className="icon-4"></i>
                </Link> */}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="home-one-testimonial-activator swiper ">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="utrains-brand-grid-wrap">
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-01.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-02.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-03.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-04.png"
                          alt="Brand Logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="utrains-brand-grid-wrap">
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08-1.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08-2.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08-3.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08-4.png"
                          alt="Brand Logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="utrains-brand-grid-wrap">
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-05.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-06.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-07.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08.png"
                          alt="Brand Logo"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="swiper-slide">
                    <div className="utrains-brand-grid-wrap">
                      <div className="brand-grid">
                        <img
                          src="/assets/images/brand/brand-08-5.png"
                          alt="Brand Logo"
                        />
                      </div>
                      <div className="brand-grid"></div>
                      <div className="brand-grid"></div>
                      <div className="brand-grid"></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompaniesStudentSection;
