import React, { useState } from 'react';
import Accordion from './Accordion';
import { Link } from 'react-router-dom'

const FAQAccordion = () => {
  const [items] = useState([
    {
      title: 'Why should I take this training?',
      content: (
        <>
          <p>
            Covid-19 came to prove that a lot of work can be done from home using only a
            computer. More and more companies are then in need of software to grow their
            business. Many of these companies are migrating their applications to the
            cloud. This has created a growing demand for IT professionals such as DevOps
            Engineers, Site Reliability Engineers, Cloud Engineers, Infrastructure
            Engineers, etc.
          </p><br></br>
          <p>
            So companies like Amazon, Google, Facebook and more need you as an IT
            professional to perform these operations.
          </p><br></br>
          <p>You should take this course to:</p><br></br>
          <ul>
            <li>Be ready to enter the high-demand and well-paid job market in the world</li>
            <li>Create your own business</li>
            <li>Be debt-free</li>
            <li>Be able to lift your family up</li>
            <li>Change your life, etc.</li>
          </ul>
        </>
      ),
      isExpanded: true,
    },
    {
      title: 'I have no computer knowledge, can I still take this course?',
      content: (
        <>
          <p>
            Yes, you don’t need any previous computer knowledge to take this course. In 6
            months, we will take you from zero to hero in the skills we teach.
          </p><br></br>
          <p>
            We have hundreds of former students around the world who have found
            life-changing works after coming to Utrain. These students were originally
            from different professions: nurse, housekeeper, courier, pharmacist,
            engineer, medical doctor, etc.
          </p><br></br>
          <p>
            So anyone with computer skills or not would do well with this training.
          </p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'What will be my future salary after this training?',
      content: (
        <>
          <p>
            While salaries can widely vary, our top students start with salaries ranging
            from $80,000 to $220,000 per year.
          </p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Where will I work after this training?',
      content: (
        <>
          <p>
            By taking this training, you will have the skills to work in tech companies
            such as AWS, Google, Accenture, Facebook, Microsoft, HP. You will also be
            able to work in the IT departments of:
          </p><br></br>
          <ul>
            <li>Banks: JP Morgan, Wells Fargo, Bank of America</li>
            <li>e-commerce and stores: Amazon, Walmart, eBay, Costco, etc.</li>
            <li>
              Private sectors: Accenture, Boeing, Booz Allen Hamilton, Man Tech,
              Coca-Cola, etc.
            </li>
            <li>Restaurants: McDonalds, Fridays, Chipotle, etc.</li>
            <li>Brand Designers: Nike, Puma, Adidas, etc.</li>
          </ul><br></br>
          <p>
          <a href="/companies/students/worked" style={{color: 'blue' }}>Click here</a> to see the complete list of companies where our former students
            work.
          </p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'What will I learn through this training program ?',
      content: (
        <>
        <ul>
            <li>Computer fundamentals,</li>
            <li>Linux Operation Systems,</li>
            <li>RedHat Preparation Courses,</li>
            <li>Fundamentals of updated DevOps Engineering tools (Git bash, Git hub, Jenkins, Docker, Tomcat, Ansible, Kubernetes, Terraform, Splunk),</li>
            <li>Fundamentals of Cloud computing (AWS),</li>
            <li>Enterprise-type hands on projects,</li>
            <li>Resume writing skills,</li>
            <li>Interview Skills.</li>
          </ul><br></br>
          <p>Be confident, if you don’t understand some of these terms, this training aims to teach them to you so that you master them.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'How long is the training program ?',
      content: (
        <>
          <p>The program last 6 months with a life-time access for retake if needed.</p><br></br>
          <p>Live online classes are on Mondays and Tuesdays from 06:00 PM to 09:30 PM Eastern time for Weekday Sessions.</p><br></br>
          <p>For Weekend sessions, live online classes are on Saturdays from 10:00 AM to 01:30 PM and Sundays from 03:00 PM to 06:30 PM Eastern Time </p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'I am not living in the USA, can I take the course ?',
      content: (
        <>
          <p>Yes, we have students from all over the world, from France, Canada, UK, Germany, Cameroon, Nigeria, etc.</p><br></br>
          <p>All our courses are totally online. So no matter where you are, you too can take this course.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Are your students really getting jobs after the program ?',
      content: (
        <>
          <p>Sure. In general, some students find their life-changing job even before the end of their training. We also have a lot of testimonials by the end of the class (students testify in front of their class mates).</p><br></br>
          <p>As other students, you will have what you need to have a job too.</p><br></br>
          <p><a href="/testimonials" style={{color: 'blue' }}>Click here</a> to see students testimonials.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'How should I enroll in the traning program ?',
      content: (
        <>
          <p>You can directly enroll through the website by <a href="/" style={{color: 'blue' }}>choosing your payment plan.</a></p>
          <p>We are always happy to assist and provide guidance. You can also contact the Utrain Help desk at :</p><br></br>
          <ul>
            <li>Tel : +1 (302) 565 5106</li>
            <l>email : contact@utrains.org</l>
          </ul><br></br>
          <p>You can also contact us from our contact page by <a href="/contact_us" style={{color: 'blue' }}>clicking here</a>.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Is the training program easy ?',
      content: (
        <>
          <p>Nothing is easy in life. It’s all about hard work, discipline and perseverance. If you are on this platform (utrains.org), it is because you have a great willingness to take this course and therefore move to another stage of your life, leaving your current level to rise according to the goal you set for yourself.</p><br></br>
          <p>The most important thing is: Willpower and passion.</p><br></br>
          <p>Others have done it, so you can do it too.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'what if I am not available the day of the class or if I miss a class?',
      content: (
        <>
          <p>The class is recorded and the video is made available to the students. So nothing to worry about.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'What if I have family emergency and cant continue to attend classes?',
      content: (
        <>
          <p>We do understand that things happen that we do not control, and for that we can accommodate students to any other upcoming session for them to catch up.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Is there any discount?',
      content: (
        <>
          <p>this course already discounted from $15000 to $3500 and also if you pay cash, you get an extra $500 discount so no more discount is available than what you see on the site</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'I am more than 60 years old can I take this course?',
      content: (
        <>
          <p>The IT field is one of few fields in which if you can crawl and type the keyboard, then you are fine. Also looking at our past students, the successful ones are the ones that were commited and had a lot of hunger for success. It was never about their age or background. So, yes you can.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Do you help with job searching ?',
      content: (
        <>
          <p>Yes we guide you on best practices when it comes to building an attractive resume, we also coach you on how to handle interviews, but at the end of the day you need to sit in the interview not us.</p>
        </>
      ),
      isExpanded: false,
    },
    {
      title: 'Are we going to get a certification at the end of this training?',
      content: (
        <>
          <p>We will not issue an end of session cert as it will not be recognized by companies but we will prep you to take the RHCSA exam cert which is recognized in the world and it shows that you know how to administer the Linux system.</p>
        </>
      ),
      isExpanded: false,
    },
  ]);

  return (
    <div className="faq-accordion">
      <Accordion items={items} />
    </div>
  );
};

export default FAQAccordion;