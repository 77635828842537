import { useState, useEffect } from "react";

const usePageLoad = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loaded = () => {
      const navigationEntries = performance.getEntriesByType("navigation");
      if (
        navigationEntries.length > 0 &&
        navigationEntries[0].loadEventEnd > 0
      ) {
        setIsLoading(false);
      }
    };

    if (document.readyState === "complete") {
      loaded();
    } else {
      window.addEventListener("load", loaded);
      const navigationObserver = new PerformanceObserver((list) => {
        for (const entry of list.getEntries()) {
          if (entry.entryType === "navigation") {
            loaded();
          }
        }
      });
      navigationObserver.observe({ entryTypes: ["navigation"] });
    }

    return () => window.removeEventListener("load", loaded);
  }, []);

  return isLoading;
};

export default usePageLoad;
