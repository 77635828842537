
import React, { useState, useEffect, useRef } from 'react';
import TestimonialContentText from './TestimonialContentText';
import Swiper from 'swiper';
 // Importation des styles Swiper

const TestimonialSlideText = ({ testimonials }) => {
  const [swiper, setSwiper] = useState(null);
  const swiperContainerRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  useEffect(() => {
    let swiperInstance;
    if (
      testimonials.length > 0 &&
      swiperContainerRef.current &&
      nextButtonRef.current &&
      prevButtonRef.current
    ) {
      swiperInstance = new Swiper(swiperContainerRef.current, {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
          nextEl: nextButtonRef.current,
          prevEl: prevButtonRef.current,
        },
      });
      setSwiper(swiperInstance);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.destroy();
      }
    };
  }, [testimonials]);

  const handleNextClick = () => {
    if (swiper && typeof swiper.slideNext === 'function') {
      swiper.slideNext();
    }
  };

  const handlePrevClick = () => {
    if (swiper && typeof swiper.slidePrev === 'function') {
      swiper.slidePrev();
    }
  };

  if (!testimonials || testimonials.length === 0) {
    return <p>Aucun témoignage disponible pour le moment.</p>;
  }

  return (
    <div className="testimonial-activation swiper" ref={swiperContainerRef}>
      <div className="swiper-wrapper">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="swiper-slide">
            <TestimonialContentText
              content={testimonial.content}
              author={{
                name: testimonial.author.name,
                // image: testimonial.author.image,
              }}
              subtitle={testimonial.author.subtitle}
              rating={testimonial.rating}
            />
          </div>
        ))}
      </div>
      <div className="swiper-navigation" style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          ref={nextButtonRef}
          className="swiper-btn-nxt"
          style={{ border: 'none' }}
          onClick={handleNextClick}
        >
          <i className="icon-west"></i>
        </button>
        <button
          ref={prevButtonRef}
          className="swiper-btn-prv"
          style={{ border: 'none' }}
          onClick={handlePrevClick}
        >
          <i className="icon-east"></i>
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlideText;