import React, { useState } from 'react';
import TabLink from './TabLink';
import TabContent from './TabContent';

function SectionJobAlert() {
  const [activeTab, setActiveTab] = useState('gn-ques');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    { id: 'gn-ques', title: 'Cloud Devops Engineer' },
    { id: 'rg-ques', title: 'Devops Engineer' },
    { id: 'ad-ques', title: 'Sr Devops Engineer' },
    { id: 'pay-option', title: 'System Engineer' },
    { id: 'com-policy', title: 'Software Engineer' },
    { id: 'terms-condition', title: 'Site Reliability Engineer' },
  ];

  return (
    <div>
      <section className="edu-section-gap faq-page-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="faq-page-nav">
                <div className="section-title">
                  <h2 className="title">JOB ALERTS!!</h2>
                  <span className="shape-line"><i className="icon-19"></i></span>
                  <span><span>Click on the Job title to get a list of all the students who got that job!</span></span>
                </div>
                <ul className="nav nav-tabs" role="tablist">
                  {tabs.map((tab) => (
                    <TabLink
                      key={tab.id}
                      title={tab.title}
                      id={tab.id}
                      isActive={activeTab === tab.id}
                      onClick={handleTabClick}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <TabContent activeTab={activeTab} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SectionJobAlert;