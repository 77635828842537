//  TestimonialSlideVideo.js
//  import React from 'react';

//  const TestimonialSlideVideo = ({ videoSrc }) => {
//    return (
//      <div className="testimonial-slide testimonial-style-2">
//        <iframe
//          className="popup-icon video-popup-activation"
//          id="video-popup-activation"
//          title="student testimonial"
//          width="100%"
//          height="400px"
//          src={videoSrc}
//          frameBorder="0"
//          allow="autoplay; encrypted-media"
//          allowFullScreen
//          style={{ borderRadius: '10px' }}
//        ></iframe>
//      </div>
//    );
//  };

//  export default TestimonialSlideVideo;
//  import React from 'react';

//  const TestimonialSlideVideo = ({ videoSrc, title }) => {
//    const renderVideoPlayer = () => {
//      if (videoSrc.includes('youtu')) {
//         //Convert YouTube URL to embeddable format
//        const embedUrl = videoSrc.replace('youtu.be/', 'www.youtube.com/embed/');
//        return (
//          <iframe
//            title="YouTube video"
//            width="100%"
//            height="400px"
//            src={embedUrl}
//            frameBorder="0"
//            allow="autoplay; encrypted-media"
//            allowFullScreen
//            style={{ borderRadius: '10px' }}
//          ></iframe>
//        );
//      } else if (videoSrc.includes('vimeo')) {
//        // Convert Vimeo URL to embeddable format
//        const embedUrl = videoSrc.replace('vimeo.com', 'player.vimeo.com/video');
//        return (
//          <iframe
//            title={title}
//            width="100%"
//            height="400px"
//            src={embedUrl}
//            frameBorder="0"
//            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
//            allowFullScreen
//            style={{ borderRadius: '10px' }}
//          ></iframe>
//        );
//      } else {
//        return <p>Video format not supported</p>;
//      }
//    };

//    return <div className="testimonial-slide">{renderVideoPlayer()}</div>;
//  };

//  export default TestimonialSlideVideo;
import React, { useState } from 'react';

const TestimonialSlideVideo = ({ videoSrc, title }) => {
  const [videoError, setVideoError] = useState(false); // État pour gérer les erreurs de vidéos

  

  const renderVideoPlayer = () => {
    if (videoSrc.includes('youtu')) {
      // Convertir l'URL YouTube en un format intégrable
      const embedUrl = videoSrc.replace('youtu.be/', 'www.youtube.com/embed/');
      return !videoError ? (
        <iframe
          title={title || 'YouTube video'}
          width="100%"
          height="400px"
          src={embedUrl}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          onError={() => setVideoError(true)} // Gestion des erreurs
          style={{ borderRadius: '10px' }}
        ></iframe>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <p>Impossible de lire la vidéo YouTube. Veuillez vérifier l'URL ou les paramètres de confidentialité.</p>
        </div>
      );
    } else if (videoSrc.includes('vimeo')) {
      // Processer et corriger l'URL Vimeo
      const embedUrl = videoSrc;
      return !videoError ? (
        <iframe
          title={title || 'Vimeo video'}
          width="100%"
          height="400px"
          src={embedUrl}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          allowFullScreen
          onError={() => setVideoError(true)} // Gestion des erreurs
          style={{ borderRadius: '10px' }}
        ></iframe>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <p>Impossible de lire la vidéo Vimeo. Veuillez vérifier l'URL ou les paramètres de confidentialité.</p>
        </div>
      );
    } else {
      return (
        <p style={{ textAlign: 'center', color: 'red' }}>
          Le format de la vidéo n'est pas pris en charge.
        </p>
      );
    }
  };

  return <div className="testimonial-slide">{renderVideoPlayer()}</div>;
};

export default TestimonialSlideVideo;