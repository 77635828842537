import { faSignOutAlt, faTachometer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from 'react';
import { getUser, logout } from "../../services/Auth";
import './styles.css';
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    const paymentPlatform = process.env.REACT_APP_STOREFRONT;
    const parsedUrl = new URL(paymentPlatform);
    const baseUrl = parsedUrl.origin;

    logout();
    const redirectUrl = `${baseUrl}/logout-url`;
    window.location.href = redirectUrl;
  };

  return (
    <div className="header-wrapper" ref={dropdownRef}>
      <ul className="header-action" role="button" onClick={toggleDropdown}>
        <li style={{ marginRight: "0" }}>
          <h6 className="heading-title" style={{ margin: "0" }}>
            {`${getUser().firstName} ${getUser().lastName}`}
          </h6>
        </li>
        <li style={{ height: "47px", margin: '0px' }}>
          <a className="dropdown-toggle-profile" onClick={toggleDropdown}>
            <i className="material-icons user-avtar wid-45 rounded-circle" style={{ fontSize: '3rem', color: 'black', opacity: '1', marginLeft: '20px' }}>
              person
            </i>
          </a>
        </li>
      </ul>
   
      {isOpen && (
        <ul className="dropdown-menu-home-page full-width">
        <li className="userprofil">
            <h5>Profil</h5>
            <br />
            <div className="d-flex align-items-center">
                <i className="material-icons user-avtar wid-45 rounded-circle " style={{ fontSize: '3rem', color: 'black', opacity: '1', }}>
                    person
                </i>
                <div className="dropdown-item mt-3"style={{ paddingLeft: '40px' }}>
                    <h6 className="mb-0 ">
                        {`${getUser().firstName} ${getUser().lastName}`}
                    </h6>
                    <span className="manee">{getUser().username}</span>
                </div>
            </div>
        </li>
        <li className="dropdown-item">
            <a href="/w">
                <FontAwesomeIcon
                    icon={faTachometer}
                    size="sm"
                    style={{ marginRight: "5px" }}
                />
                User dashboard
            </a>
        </li>
        <li className="dropdown-item" onClick={handleLogout}>
            <FontAwesomeIcon
                icon={faSignOutAlt}
                size="sm"
                style={{ marginRight: "5px" }}
            />
            Logout
        </li>
    </ul>
      )}
    </div>
  );
};

export default Dropdown;