import React from "react";
import SessionAllDetail from "./SessionAllDetail";
import SessionDetails from "./SessionDetails";

function CourseDetails({ data, sessionContentData }) {

  return (
    <section className="edu-section-gap course-details-area edu-section-gap-custom">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-8">
            <div className="course-details-content">
              <ul
                className="nav nav-tabs scrollmenu nav-next-session-custom"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="installment-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#installment"
                    type="button"
                    role="tab"
                    aria-controls="overview"
                    aria-selected="true"
                  >
                    Installment
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="overview-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#overview"
                    type="button"
                    role="tab"
                    aria-controls="overview"
                    aria-selected="true"
                  >
                    Overview
                  </button>
                </li>
                {true === false ? (
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="carriculam-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#carriculam"
                      type="button"
                      role="tab"
                      aria-controls="carriculam"
                      aria-selected="false"
                    >
                      Session Content
                    </button>
                  </li>
                ) : null}
              </ul>

              <SessionAllDetail
                data={data}
                sessionContentData={sessionContentData}
              />
            </div>
          </div>
          <SessionDetails
         fileUrl={data?.coverVideo || data?.coverPicture}
         data={data}
          />
        </div>
      </div>
    </section>
  );
}

export default CourseDetails;
