import { Link } from "react-router-dom"

function WhyUsSection() {

    return (
        <>
            <div className="edu-categorie-area categorie-area-2 edu-section-gap">
                <div className="container">
                    {/* <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <h2 className="title">Why Us ?</h2>
                        <span className="shape-line"><i className="icon-19"></i></span>
                        <p>It matters where you study. Utrains offer a unique combination of top-tier training, support and affordability.</p>
                    </div> */}
                    <div id="why-us-section" className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <h2 className="title">Why Us ?</h2>
                        <span className="shape-line"><i className="icon-19"></i></span>
                        <p>It matters where you study. Utrains offer a unique combination of top-tier training, support and affordability.</p>
                    </div>

                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-primary-style ">
                                <div className="icon">
                                    <i className="icon-9"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">One-on-one coaching</h5>
                                    </Link>
                                    <br />We provide you with individualized guidance to help you overcome any challenges you may face.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-secondary-style">
                                <div className="icon">
                                    <i className="icon-10 art-design"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Flexible payment plans</h5>
                                    </Link>
                                    <br /> We offer payment plans to make training affordable. Also discounts are available for one time payment.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra01-style">
                                <div className="icon">
                                    <i className="icon-11 personal-development"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">From zero to hero</h5>
                                    </Link>
                                    <br /> We set you up for success in the IT industry. Top students can go from earning zero to six figures.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-tertiary-style">
                                <div className="icon">
                                    <i className="icon-12 health-fitness"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Job support</h5>
                                    </Link>
                                    <br /> Gain access to a wide range of job postings from top companies in your desired industry. We provide training along with interviews tips and resume workshops.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra02-style">
                                <div className="icon">
                                    <i className="icon-13 data-science"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Latest technology</h5>
                                    </Link>
                                    <br /> Train on in-demand software with courses constantly updated to new trends in the industry.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra04-style">
                                <div className="icon">
                                    <i className="icon-15"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">24/7 Support</h5>
                                    </Link>
                                    <br /> We are committed to providing a supportive learning environment. We offer humain support at anytime. This is in addition to live sessions with experts during the week.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra03-style">
                                <div className="icon">
                                    <i className="icon-14"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Accessibility</h5>
                                    </Link>
                                    <br /> Our courses are designed to be accessible to everyone,
                                    without requiring any prior experience in the IT field.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra05-style">
                                <div className="icon">
                                    <i className="icon-16 computer-science"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Bridge the gap</h5>
                                    </Link>
                                    <br /> Our training courses allow you to develop your skills in a short time,
                                    without the need for expensive diplomas.
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div className="categorie-grid categorie-style-2 color-extra06-style">
                                <div className="icon">
                                    <i className="icon-17 video-photography"></i>
                                </div>
                                <div className="content">
                                    <Link to="/course_one">
                                        <h5 className="title">Curriculum</h5>
                                    </Link>
                                    <br /> Discover a comprehensive and affordable program that will allow you
                                    to excel in DevOps and Cloud engineering.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyUsSection
