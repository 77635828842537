import React from 'react';

function TestimonialHeader() {
  return (
    <div className="testimonial-heading-area">
      <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
        <span className="pre-title pre-textsecondary">Testimonials</span>
        <h2 className="title">What Our Students Have To Say</h2>
        <span className="shape-line"><i className="icon-19"></i></span>
        <p>These testimonies highlight unique experiences and deep emotions.</p>
      </div>
    </div>
  );
}

export default TestimonialHeader;