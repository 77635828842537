import React from "react";
import { Link } from "react-router-dom";
import Time from "../Time";
import { formatCourseTitle } from "../../../../utils/helpers";

function BannerCustom({ data }) {
 
  console.log("DATA Params", data)
  return (
    <div
      className="banner-custom"
      style={{
        backgroundImage:
          `url(${data.bannerImage})`,
      }}
    >
      <div className="edu-breadcrumb-area breadcrumb-style-3 breadcrumb-style-3-custom banner-shadow">
        <div className="container">
          <div className="breadcrumb-inner">
            <ul className="edu-breadcrumb">
              <li className="breadcrumb-item next-session-banner-color">
                <Link to="/" className="next-session-banner-color">
                  Home
                </Link>
              </li>
              <li className="separator">
                <i className="icon-angle-right "></i>
              </li>
              <li
                className="breadcrumb-item active next-session-banner-color"
                aria-current="page"
              >
                {formatCourseTitle(data?.title)}
              </li>
            </ul>
            <div className="page-title custom-page-title">
              <h1 className="title next-session-banner-color">{data?.title}</h1>
            </div>
            {/* <ul className="course-meta">
              <li className="next-session-banner-color">
                <i className="icon-58 next-session-banner-icon"></i>by Edward
                Norton
              </li>
              <li className="next-session-banner-color">
                <i className="icon-59 next-session-banner-icon"></i>English
              </li>
              <li className="course-rating next-session-banner-color">
                <div className="rating next-session-banner-color">
                  <i className="icon-23"></i>
                  <i className="icon-23"></i>
                  <i className="icon-23"></i>
                  <i className="icon-23"></i>
                  <i className="icon-23"></i>
                </div>
                <span className="rating-count next-session-banner-color">
                  (720 Rating)
                </span>
              </li>
            </ul> */}
            <div className="custom-time">
              <h1>
                <Time data={{ data }} />
              </h1>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-1">
            data
            <span></span>
          </li>
          <li className="shape-2 scene">
            <img
              data-depth="2"
              src="/assets/images/about/shape-13.png"
              alt="shape"
            />
          </li>
          <li className="shape-3 scene">
            <img
              data-depth="-2"
              src="/assets/images/about/shape-15.png"
              alt="shape"
            />
          </li>
          <li className="shape-4">
            <span></span>
          </li>
          <li className="shape-5 scene">
            <img
              data-depth="2"
              src="/assets/images/about/shape-07.png"
              alt="shape"
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BannerCustom;
