import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { parseDataBlocks } from "../../../../../utils/helpers";

function SessionAllDetail({ data, sessionContentData }) {
  const [paymentDescription, setPaymentDescription] = useState([]);

  const setupPaymentDescription = () => {
    setPaymentDescription(parseDataBlocks(data?.paymentDescription));
    console.log(
      "data setupPaymentDescription:",
      parseDataBlocks(data?.paymentDescription)
    );
  };

  useEffect(() => {
    setupPaymentDescription();

    console.log("paymentDescription: ", paymentDescription);
  }, [data]);

  return (
    <div className="tab-content" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="installment"
        role="tabpanel"
        aria-labelledby="overview-tab"
      >
        <div className="row course-tab-content">
          {paymentDescription &&
            paymentDescription.map((installment, index) => (
              <>
                <div
                  className="col-lg-4 next-session-pricing-table-custom"
                  //   data-sal-delay="100"
                  //   data-sal="slide-up"
                  //   data-sal-duration="800"
                  key={index}
                >
                  <div className="pricing-table">
                    <div className="pricing-header">
                      <h6 className="title">{installment.name}</h6>
                      <h6>{installment.sessionName}</h6>
                      <div className="price-wrap">
                        <span className="amount">${installment.price}</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-item">
                        {installment.description?.split(";;").map((item, i) => (
                          <li className="ns-info" key={i}>
                            <i className="icon-20"></i>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {data.status === "OPENED" ? (
                      <div className="pricing-btn">
                        <Link
                          className="edu-btn btn-border btn-medium activee"
                          to={installment.paymentLink}
                        >
                          Enroll Now<i className="icon-east"></i>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="overview"
        role="tabpanel"
        aria-labelledby="overview-tab"
      >
        <div className="course-tab-content">
          <div className="course-overview">
            <h3 className="heading-title">Course Description</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data ? data.description : "",
              }}
            ></div>
          </div>
        </div>
      </div>

      <div
        className="tab-pane fade"
        id="carriculam"
        role="tabpanel"
        aria-labelledby="carriculam-tab"
      >
        <div className="course-tab-content">
          <div className="course-curriculam">
            <h3 className="heading-title">Session Content</h3>

            {sessionContentData && sessionContentData.length !== 0
              ? sessionContentData.map((_module, index) => (
                  <div className="course-lesson">
                    <h5 className="title">{_module?.title}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _module ? _module.description : "",
                      }}
                    ></p>
                    <ul>
                      {_module.lessons && _module.lessons.length !== 0
                        ? _module.lessons.map((lesson, index) => (
                            <li>
                              <div className="text">
                                <i className="icon-65"></i> {lesson.title}
                              </div>
                              <div className="icon">
                                <span className="badge badge-primary">
                                  {lesson.topics.length} Topic(s)
                                </span>
                                <i className="icon-68"></i>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionAllDetail;
