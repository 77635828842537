
import { formatDate } from "../../../utils/helpers"

function MarketingSection({ currentSession }) {

    const Marketing = [
        { videoSrc: 'https://www.youtube.com/embed/c7I23BuNEHc?autoplay=1' },

    ];
    // const Marketing = [
    //     { videoSrc: "/assets/vid.mp4" },

    // ];
    return (
        <>
            <div className="edu-section-gap edu-about-area about-style-8">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <span className="pre-title">Our Next class </span>
                                    <h2 className="title">Ready to Join Our <span className="color-secondary">Next Class?</span> </h2>
                                    <span className="shape-line"><i className="icon-19"></i></span>
                                    <p>Did you know that DevOps engineers earn an average of $120,000 or more per year? There are fewer DevOps engineers available while the demand is higher and continuously growing. Therefore, companies are willing to pay generously to hire those with DevOps skills.</p>
                                    <p>Would you like to embark on a lucrative career in the world of IT? Are you looking for a career change? Join our next DevOps & Cloud session on {formatDate(currentSession?.startDate, 2)}. This is an excellent opportunity to enhance your skills and knowledge in the field of DevOps and Cloud Computing. Don’t miss out! Enroll now by clicking on the button below. </p>
                                    <div className="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                        <a href={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`} className="edu-btn">Enroll Now<i className="icon-4"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="testimonial-container">
                                {Marketing.map((Marketing, index) => (
                                    <div key={index} className="testimonial">
                                        <iframe
                                            width="100%"
                                            height="400"
                                            src={Marketing.videoSrc}
                                            frameBorder="0"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* <div className="mt-6 col-lg-12">
                            <h4 className="title">What Will You Learn with Us ?</h4>
                        </div> */}
                        <div id="learning-section" className="mt-6 col-lg-12">
                            <h4 className="title">What Will You Learn with Us ?</h4>
                        </div>
                        <div className="col-lg-12 utrains-content-to-learning">
                            <div className="online-art-testimonial-activation swiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://as2.ftcdn.net/v2/jpg/03/19/73/27/1000_F_319732708_zELyIzNZxSbpsGfosR7J7O38jJNxb3hQ.jpg" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Devops</h4>
                                                <p> DevOps is a collaborative approach that combines software development (Dev) and IT operations (Ops) practices to improve efficiency, continuous delivery, and software quality by automating and integrating development and deployment processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Cloud-icon.svg/1130px-Cloud-icon.svg.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Cloud</h4>
                                                <p> Cloud computing is a computing model that allows access to computing resources (servers, storage, databases, etc.) via the Internet. It offers flexibility, scalability, and economy by allowing users to pay only for the resources they use.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZAU9CNvqV594AsSYR8oNcwrxLfwHCM-a86g&s" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">kubernetes</h4>
                                                <p> Kubernetes is an open-source platform for automating the deployment, scaling, and management of containerized applications. It orchestrates containers to ensure their availability, performance, and efficient management in production environments.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://www.jenkins.io/images/logos/jenkins/jenkins.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Jenkins</h4>
                                                <p> Jenkins is an open-source automation server used to automate software development tasks, including building, testing, and deploying. It facilitates continuous integration (CI) and continuous delivery (CD) by enabling fast and reliable code updates.	</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://hashicorp.gallerycdn.vsassets.io/extensions/hashicorp/terraform/2.31.2024061114/1718208268699/Microsoft.VisualStudio.Services.Icons.Default" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Terraform</h4>
                                                <p> Terraform is an infrastructure-as-code (IaC) tool for defining and provisioning complete infrastructures in cloud and on-premises environments. It uses configuration files to automate the creation and management of IT resources</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://miro.medium.com/v2/resize:fit:1400/1*bZP17SmwRZihfAYDr5KBFg.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Docker</h4>
                                                <p> Docker is an open-source platform that allows you to build, deploy, and run applications in containers. Containers are lightweight, portable, and encapsulate everything an application needs to run, ensuring consistent execution across different environments.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://as2.ftcdn.net/v2/jpg/03/19/73/27/1000_F_319732708_zELyIzNZxSbpsGfosR7J7O38jJNxb3hQ.jpg" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Ansible</h4>
                                                <p>Ansible is a configuration management and automation tool that uses simple YAML-like files to automate application deployment, configuration management, task orchestration, and server provisioning, without requiring client software on the managed machines.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://avatars.githubusercontent.com/u/18133?s=280&v=4" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Git</h4>
                                                <p> Git is a distributed version control system that allows developers to track changes to source code, collaborate with other developers, and manage different versions of the project. It makes it easy to manage branches, merges, and revision histories.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Prometheus_software_logo.svg/1200px-Prometheus_software_logo.svg.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Prometheus</h4>
                                                <p> Prometheus is an open-source monitoring and alerting system designed for application and infrastructure monitoring. It collects metrics, stores them in a time-series database, and provides real-time alerting and visualization capabilities.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/1200px-Tux.svg.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Linux</h4>
                                                <p> Linux is an open-source operating system widely used in server and development environments. It offers great flexibility, stability, and security, and is the basis for many DevOps tools and technologies</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="single-item" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                            <div className="icon color-extra02">
                                                <img className="w-15" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1200px-Python-logo-notext.svg.png" alt="" srcset="" />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">Python</h4>
                                                <p> Python is a versatile programming language popular for its readable code and simplicity. It is commonly used in DevOps for task automation, scripting, configuration management, and tool integration.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-navigation">
                                    <div className="swiper-btn-prv">
                                        <img src="assets/images/svg-icons/icon-left.svg" alt="images left" />
                                    </div>
                                    <div className="swiper-btn-nxt">
                                        <img src="assets/images/svg-icons/icon-right.svg" alt="images right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarketingSection