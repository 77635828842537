
import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null); // Tracks the currently opened accordion item

  const handleToggle = (index) => {
    // Toggle the clicked item or close it if it's already open
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="accordion" id="faq-accordion">
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <h5 className="accordion-header">
            <button
              className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
              type="button"
              onClick={() => handleToggle(index)} // Handle toggle click
              aria-expanded={activeIndex === index}
            >
              {item.title}
            </button>
          </h5>
          <div
            id={`question-${index}`}
            className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
          >
            <div className="accordion-body">
              <p>{item.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;