import React from 'react';

function TestimonialSlide({ imageUrl }) {
  return (
    <div className="testimonial-slide">
      <img decoding="async" src={imageUrl} className="attachment-large " alt="" />
    </div>
  );
}

export default TestimonialSlide;