
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import './styles.css';
function Footer() {
    const [isHovered, setIsHovered] = useState(null);
    const [hoveredLink, setHoveredLink] = useState(null);

    const links = [
        { href: '/', label: 'Home', icon: 'icon-36' },
        { href: '/contact_us', label: 'Contact', icon: 'icon-71' },
        { href: '/faq', label: "FAQ's", icon: 'icon-28' },
        { href: '/feedback', label: 'Feedback', icon: 'icon-38' },
        { href: '/testimonials', label: 'Testimonials', icon: 'icon-44' },
       
    ];
    return (
        <footer className="edu-footer footer-lighten bg-image footer-style-1">
            <div className="footer-top">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-5 col-md-6">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img className="logo-light" src="/assets/images/logo/logo-dark.png" alt="Corporate Logo" />
                                        <img className="logo-dark" src="/assets/images/logo/logo-white.png" alt="Corporate Logo" />
                                    </Link>
                                </div>
                                <p className="description">
                                    Welcome to the Linux and DevOps system administration training program. <br /> <br />
                                    Some of you may be asking the question what is Linux?  Well it’s normal to ask if you are not an IT professional. If that is the case, then …
                                    {/* Some of you may be asking the question what is Linux?  Well it’s normal to ask if you are not an IT professional.  */}
                                </p>
                                
                            </div>
                        </div>
                       
                        <div className="col-lg-5 col-md-6 ">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">Contacts</h4>
                                <p className="description"><i className="icon-4"></i> 7070 Knights Ct, Missouri City TX 77459-5225</p>
                                <p className="description"><i className="icon-phone"></i> +1 (302) 689 3440</p>
                                <p className="description"><i className="icon-envelope"></i> contact@utrains.org</p>
                                <h5>Join us on social media</h5>
                                <ul className="social-share">
                                    <li><Link to="https://www.facebook.com/utrains.org" className="color-fb"><i className="icon-facebook"></i></Link></li>
                                    <li><Link to="https://www.linkedin.com/company/utrains/" className="color-linkd"><i className="icon-linkedin2"></i></Link></li>
                                    <li><Link to="https://www.youtube.com/@utrains" className="color-yt"><i className="icon-youtube"></i></Link></li>
                                </ul>

                            </div>
                        </div>

                        {/* <div className="col-lg-1 col-sm-6">
                        </div> */}
                        <div className="col-lg-2 col-md-6">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">Links</h4>
                                <ul className="footer-link link-hover">
                                    {links.map((link, index) => (
                                        <li
                                            key={index}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <i className={link.icon} style={{ marginRight: '8px' }}></i>
                                            <a
                                                href={link.href}
                                                style={{

                                                    color: hoveredLink === index ? 'blue' : 'black',
                                                    textDecoration: 'none'
                                                }}
                                                onMouseEnter={() => setHoveredLink(index)}
                                                onMouseLeave={() => setHoveredLink(null)}
                                            >
                                                {link.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center inner">
                                <p>Copyright 2021 <Link to="https://www.utrains.org" target="_blank">Utrains Team</Link> All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="responsive-page">
                    <ul className="footer-link link-hover" style={{ listStyle: 'none', padding: 0 }}>
                        <li style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="" style={{ marginRight: '8px' }}></i>
                            <a
                                href="/terms-of-use"
                                style={{
                                    fontSize: '20px',
                                    color: isHovered ? 'blue' : 'black',
                                    textDecoration: 'none'
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Terms of use
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div className="read-white copyright-area" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner" style={{ textAlign: 'left', margin: '0 90px' }}>
                                <p>© All rights reserved</p>
                            </div>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner" style={{ textAlign: 'right', margin: '0 90px' }}>
                                <p>Powered ❤ by utrains</p>
                            </div>
                        </div>
                </div>
            </div>

     
        </footer>
    )
}

export default Footer
