import { match } from 'path-to-regexp';
import dayjs from "dayjs";

function generateDigitalChain() {
    let chaine = '';
    const longueurChaine = 10;
    const caracteresPermis = '0123456789';
  
    for (let i = 0; i < longueurChaine; i++) {
      const indexAleatoire = Math.floor(Math.random() * caracteresPermis.length);
      chaine += caracteresPermis.charAt(indexAleatoire);
    }
  
    return chaine;
}

const doesPathMatchRoute = (routePath, currentPath) => {
  const matcher = match(routePath, { decode: decodeURIComponent });
  const matched = matcher(currentPath);
  return matched !== false;
};

function urlToSlug(url) {
  // 1. Convertir l'URL en minuscules
  let slug = url.toLowerCase();

  // 2. Remplacer les caractères spéciaux par des tirets
  slug = slug.replace(/[^a-z0-9]+/g, '-');

  // 3. Supprimer les tirets en début et fin de chaîne
  slug = slug.replace(/^-+|-+$/g, '');

  return slug;
}

/**
 *
 * @param date
 * @param format
 * @returns
 */
 const formatDate = (date, format = 0) => {
	switch (format) {
		case 1:
			return dayjs(date).format("YYYY-MM-DD");
		case 2:
			return dayjs(date).format("DD MMM YYYY");
		case 3:
			return dayjs(date).format("DD MMM YYYY, HH:mm");
		default:
			return dayjs(date).format("DD MMMM YYYY").toString();
	}
}

const validate = (data, rules) => {
  const errors = {};

  for (const field in rules) {
    const value = data[field];
    const fieldRules = rules[field];

    // Vérification si le champ est requis et vide
    if (fieldRules.required && !value) {
      errors[field] = `The field is required`; // Message de champ requis
      continue; // Passer à la prochaine itération si le champ est vide
    }

    // Validation des autres règles seulement si le champ n'est pas vide
    if (fieldRules.minLength && value.length < fieldRules.minLength) {
      errors[field] = `${field} must be at least ${fieldRules.minLength} characters`;
    }

    if (fieldRules.maxLength && value.length > fieldRules.maxLength) {
      errors[field] = `${field} must be no more than ${fieldRules.maxLength} characters`;
    }
    
    if (fieldRules.isNumber && isNaN(value)) {
      errors[field] = `${field} must be a number`;
    }

    if (fieldRules.isDate && isNaN(Date.parse(value))) {
      errors[field] = `${field} must be a valid date`;
    }

    // Validation d'email
    if (fieldRules.isEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      errors[field] = `${field} must be a valid email address`;
    }

    // Validation d'URL
    if (fieldRules.isURL && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
      errors[field] = `${field} must be a valid URL`;
    }

    // Validation par motif
    if (fieldRules.pattern && !fieldRules.pattern.test(value)) {
      errors[field] = `${field} is invalid`;
    }

    // Validation de fichier
    if (fieldRules.isFile) {
      if (fieldRules.maxFileSize && value.size > fieldRules.maxFileSize) {
        errors[field] = `${field} must be smaller than ${fieldRules.maxFileSize / 1024 / 1024}MB`;
      }

      if (fieldRules.acceptedFormats && !fieldRules.acceptedFormats.includes(value.type)) {
        errors[field] = `${field} must be one of the following formats: ${fieldRules.acceptedFormats.join(', ')}`;
      }
    }

    // Validation de sélection
    if (fieldRules.select && !fieldRules.values.includes(value)) {
      errors[field] = `${field} must be one of the following values: ${fieldRules.values.join(', ')}`;
    }
  }

  return errors;
};

const generateQueryParams = (params) => {
	const urlSearchParams = new URLSearchParams();
	for (const [key, value] of Object.entries(params)) {
	  if(value) urlSearchParams.append(key,  value);
	}
	return '?' + urlSearchParams.toString();
}

const getLastBlock = (str) => {
  const blocks = str.split('-');
  const lastBlock = blocks[blocks.length - 1];

  // Vérifie si le dernier bloc est un nombre entier
  const parsedNumber = parseInt(lastBlock, 10);
  if (!isNaN(parsedNumber)) {
    return parsedNumber;
  } else {
    return null;
  }
}

const getBullet = (index) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const base = 26; // Total letters in English alphabet

  if (index <= 0) {
      throw new Error("Index must be greater than 0");
  }

  if (index <= base) {
      // Direct mapping for first 26 indexes
      return alphabet[index - 1];
  } else {
      // Adjust index for the next phase (A1, A2, ...)
      index -= base;
      
      // Function to convert a number to base-26 with custom alphabet
      function numberToBase26(num) {
          let result = "";
          let n = num;
          
          while (n > 0) {
              n--; // Adjust for zero-based index
              const remainder = n % base;
              result = alphabet[remainder] + result;
              n = Math.floor(n / base);
          }

          return result;
      }

      // Calculate the primary letter
      const primaryLetter = numberToBase26(Math.ceil(index / base));

      // Calculate the suffix number
      const suffixNumber = (index % base === 0) ? base : (index % base);

      return primaryLetter + suffixNumber;
  }
}

const parseDataBlocks = (data) => {

  // Initialisation du tableau pour stocker les objets résultants
  const result = [];

  if (data) {
    // Séparation des blocs en utilisant le séparateur
    const blocks = data
      .split("[SEPARATOR]")
      .map((block) => block.trim())
      .filter(Boolean);
    
    // Parcourir chaque bloc
    blocks.forEach((block) => {
      // Séparer chaque ligne dans le bloc
      const lines = block
        .split("\n")
        .map((line) => line.trim())
        .filter(Boolean);

      // Initialisation de l'objet qui stocke les paires clé/valeur
      const obj = {};

      // Parcourir chaque ligne pour extraire les paires clé/valeur
      lines.forEach((line) => {
        // Chaque ligne est sous la forme "clé : valeur"
        const [key, value] = line.split("::").map((part) => part.trim());

        // Ajouter la paire clé/valeur à l'objet
        if (key && value) {
          obj[key] = value;
        }
      });

      // Ajouter l'objet au tableau final
      result.push(obj);
    });

    return result;
  } else return result;
};

const formatCourseTitle = (title) => {
  if (title) {
    // Première étape : découper le titre en segments
    const firstSegment = title.slice(0, 15).trim(); // Limiter à 18 caractères
    const lastSegment = title.slice(-8).trim(); // Garder les 8 derniers caractères

    // Construire le titre formaté
    const formattedTitle = `${firstSegment} ... ${lastSegment}`;

    return formattedTitle;
  } else {
    return null;
  }
};
export {
  generateDigitalChain,
  doesPathMatchRoute,
  urlToSlug,
  formatDate,
  generateQueryParams,
  validate,
  getLastBlock,
  getBullet,
  parseDataBlocks,
  formatCourseTitle,
};