import React from 'react'

import ContactUsContent from '../../components/pages/ContactUs/ContactUsContent';

function ContactUs() {
  return (
    <div>
      <ContactUsContent />
    </div>
  )
}

export default ContactUs
