import React from 'react';
import TestimonialSlide from './TestimonialSlide';

function TestimonialCarousel() {
  const testimonialImages = [

    'assets/images/testimonial/testimoniale1.png',
    'assets/images/testimonial/testimoniale2.png',
    'assets/images/testimonial/testimoniale3.png',
    'assets/images/testimonial/testimoniale4.png',
    'assets/images/testimonial/testimoniale5.png',
    'assets/images/testimonial/testimoniale6.png',
    'assets/images/testimonial/testimoniale7.jpeg',
    'assets/images/testimonial/testimoniale8.png',
    'assets/images/testimonial/testimoniale9.png',
    'assets/images/testimonial/testimoniale10.jpeg',
    'assets/images/testimonial/testimoniale11.jpeg',
    'assets/images/testimonial/testimoniale12.jpeg',
    'assets/images/testimonial/testimoniale13.png',
    'assets/images/testimonial/testimoniale14.png',
    'assets/images/testimonial/testimoniale15.png',
    'assets/images/testimonial/testimoniale16.png',
    'assets/images/testimonial/testimoniale17.png',
    'assets/images/testimonial/testimoniale18.png',
    'assets/images/testimonial/testimoniale19.png',
    'assets/images/testimonial/testimoniale20.png',
    'assets/images/testimonial/testimoniale21.png',
    'assets/images/testimonial/testimoniale22.jpeg',
    'assets/images/testimonial/testimoniale23.jpeg',
    'assets/images/testimonial/testimoniale24.png',
    
    
  ];

  return (
    <div className="business-testimonial-activation swiper">
      <div className="swiper-wrapper">
        {testimonialImages.map((imageUrl, index) => (
          <div className="swiper-slide" key={index}>
            <TestimonialSlide imageUrl={imageUrl} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestimonialCarousel;