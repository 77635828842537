import React from 'react';

const TestimonialContentText = ({ content, author, subtitle, rating }) => {
  return (
    <div className="testimonial-slide">
      <div className="content">
        <p>{content}</p>
        <div className="rating-icon">
          {Array.from({ length: rating }, (_, i) => (
            <i key={i} className="icon-23"></i>
          ))}
        </div>
      </div>
      <div className="author-info">
        {/* <div className="thumb">
          <img src={`assets/images/testimonial/${author.image}`} alt="Testimonial" />
        </div> */}
        <div className="info">
          <h5 className="title">{author.name}</h5>
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default TestimonialContentText;