/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import FadeSlider from "./Slider/FadeSlider";

function BannerSection({ mixedSlides, currentSession, isLoading }) {

  return (
    <FadeSlider
      slides={mixedSlides}
      autoplayInterval={3000}
      isLoading={isLoading.getBannerData.value}
      currentSession={currentSession}
    />
  );
}

export default BannerSection
