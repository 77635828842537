import { Link } from "react-router-dom";

function CTABannerSection({ currentSession }) {
  return (
    <>
      <div className="edu-cta-banner-area home-one-cta-wrapper bg-image">
        <div className="container">
          <div className="edu-cta-banner">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div
                  className="section-title section-center"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  <h2 className="title">
                    Access our{" "}
                    <span className="color-secondary">
                      Next Devops & IT Training Session{" "}
                    </span>{" "}
                    launched by Utrains{" "}
                  </h2>
                  {currentSession ? (
    <a
        href={`/sessions/next/${currentSession?.slug}-${currentSession?.id}`}
        className="edu-btn"
    >
        Join now <i className="icon-4"></i>
    </a>
) : null}
                </div>
              </div>
            </div>
            <ul className="shape-group">
              <li className="shape-01 scene">
                <img
                  data-depth="2.5"
                  src="/assets/images/cta/shape-10.png"
                  alt="shape"
                />
              </li>
              <li className="shape-02 scene">
                <img
                  data-depth="-2.5"
                  src="/assets/images/cta/shape-09.png"
                  alt="shape"
                />
              </li>
              <li className="shape-03 scene">
                <img
                  data-depth="-2"
                  src="/assets/images/cta/shape-08.png"
                  alt="shape"
                />
              </li>
              <li className="shape-04 scene">
                <img
                  data-depth="2"
                  src="/assets/images/about/shape-13.png"
                  alt="shape"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CTABannerSection;
