
import React from 'react';
import TestimonialHeadingVideo from './TestimonialHeadingVideo';
import TestimonialCarouselVideo from './TestimonialCarouselVideo';

const TestimonialVideo = () => {
  const testimonials = [
    { videoSrc: 'https://youtu.be/p4RI-QX9GTM' },
    { videoSrc: 'https://player.vimeo.com/video/748824639', title: 'alma&daris' },
    { videoSrc: 'https://player.vimeo.com/video/748839383', title: 'emanuel&wife.mp4' },
    { videoSrc: 'https://player.vimeo.com/video/748838302', title: 'ismel_1.mp4' },
    { videoSrc: 'https://player.vimeo.com/video/748836394', title: 'herve.mp4' },
    { videoSrc: 'https://player.vimeo.com/video/748835577', title: 'ruth' },
    { videoSrc: 'https://player.vimeo.com/video/748834724', title: 'mongo family' },
    { videoSrc: 'https://player.vimeo.com/video/748831449', title: 'Omu.mp4' },
    
  ];

  return (
    <div className="testimonial-area-4">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-5">
            <TestimonialHeadingVideo
              preTitle="Testimonials"
              title="A lot of happy students"
              description="We are happy to see more than a thousand happy students expressing their testimonies after completing our program. Are you next?"
            />
          </div>
          <div className="col-lg-7 edu-order-md-1">
            <TestimonialCarouselVideo testimonials={testimonials} />
          </div>
        </div>
      </div>
      <ul className="shape-group">
        <li className="shape-1 scene">
          <img data-depth="2" src="assets/images/others/shape-18.png" alt="Shape" />
        </li>
        <li className="shape-2">
          <img src="assets/images/others/map-shape-3.png" alt="Shape" />
        </li>
      </ul>
    </div>
  );
};

export default TestimonialVideo;